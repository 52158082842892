/* eslint-disable prefer-destructuring */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { GradeInterface } from "@/ts/interfaces/Checkout/GradeInterface";
import { router } from "@inertiajs/react";

import { ClassesEnum } from "@ts/enums/ClassesEnum";
import { PageQueryParamsInterface } from "@ts/interfaces/Checkout/PageQueryParamsInterface";
import { PlanInterface } from "@ts/interfaces/Checkout/PlanInterface";

export const sortItems = <T extends Record<keyof T, unknown>>(
    data: T[],
    sorterKey: keyof T,
    childrenKeys: string[]
): T[] => {
    if (data.length <= 0) return [];
    data.sort(
        (item1, item2) =>
            (item1[sorterKey] as number) - (item2[sorterKey] as number)
    );
    // eslint-disable-next-line prefer-const
    for (let childKey of childrenKeys) {
        data.forEach((d: Record<string, unknown>) => {
            if (Object.prototype.hasOwnProperty.call(d, childKey)) {
                sortItems((d[childKey] ?? []) as T[], sorterKey, childrenKeys);
            }
        });
    }
    return data;
};

export const sortProducts = <T extends Partial<Record<keyof T, any>>>(
    data: T[],
    sorterKey: keyof T
): T[] => {
    if (data.length <= 0) return [];
    data.sort(
        (item1, item2) =>
            (item1[sorterKey] as number) - (item2[sorterKey] as number)
    );
    return data;
};

export const formatPrice = (price: number, currency?: string) =>
    `$${price.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })} ${currency ?? "AUD"}`;

export const formatPriceSimple = (price: number) =>
    price.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

export const sortByProperty = <T>(array: T[], property: keyof T) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return array
        .slice()
        .sort((a, b) => (a[property] as any) - (b[property] as any));
};

/**
 * Retrieves and returns the final segment of a specified path string.
 * @param {string} path - The path string from which the last segment is extracted.
 * @returns {string} - The last segment found within the provided URL path.
 * eg: http://portal.local/parents/dashboard returns /dashboard
 */
export const getLastPathSegment = (path: string): string => {
    const segments = path?.split("/");
    return segments[segments.length - 1];
};

/**
 * Checks whether the provided URL corresponds to the current page.
 * @param {string} url - The URL for the page.
 * @returns {boolean} - True if it is the current URL; false otherwise.
 */
export const isActiveUrl = (url: string): boolean => {
    const desiredPath = new URL(url).pathname;

    const currentPath = window.location.pathname;
    console.log("url", url);
    console.log("desiredPath", desiredPath);
    console.log("currentPath", currentPath);

    return currentPath?.startsWith(desiredPath);
};

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/;

export const MAX_NAME_LENGTH = 16;

export const truncateName = (
    name: string,
    maxLen: number = MAX_NAME_LENGTH
) => {
    return name?.length > maxLen ? `${name.slice(0, maxLen)}...` : name;
};

export const removeNullProperties = (
    obj: PageQueryParamsInterface
): { [key: string]: string } =>
    Object.fromEntries(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(obj).filter(([_, value]) => value !== null)
    );

export const getTrackingId = (
    classes: ClassesEnum[],
    action: string | number
) => {
    return `${classes.join(".")}.${action}`;
};

export const nameRegex = /^[a-zA-Z .'-]+$/g;

export const getPlansForTargetGrade = (
    plans: PlanInterface[],
    targetGrade: string
) => {
    const targetGradePlans = plans.filter(
        p => p.targetGradeName === targetGrade
    );

    if (targetGradePlans?.length > 0) {
        return targetGradePlans;
    }
    return plans;
};

/**
 * Intercom modal is used in few places and it might use in other places in future too.
 * @param closeFunc
 */
export const closeModalOpenIntercom = (closeFunc: () => void): void => {
    window?.Intercom("show");
    closeFunc();
};

export const formatSubjects = (
    subjects: Array<{ type: string; id: number; name: string }>
) => {
    return subjects?.map(subject => ({
        ...subject,
        value: subject.id,
        label: subject.name
    }));
};

export const returnGradeShortName = (
    grade: string | undefined,
    useAbbreviation: boolean = false
) => {
    switch (grade) {
        case "G9":
            return useAbbreviation ? "G9" : "Grade 9";
        case "G10":
            return useAbbreviation ? "G10" : "Grade 10";
        case "G11":
        case "Grade 11: University":
        case "Grade 11: Non-assessed":
            return useAbbreviation ? "G11" : "Grade 11";
        case "G12":
        case "Grade 12: University":
        case "Grade 12: Non-assessed":
            return useAbbreviation ? "G12" : "Grade 12";
        default:
            return grade;
    }
};

export const generateHash = (str: string, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString();
};

export const addressRegex = /^[a-zA-Z0-9\s#,/.-]+$/;
export const postalCodeRegex = /^[a-zA-Z0-9]+$/;
export const phoneRegex = /^\d+$/;
export const suburbOrCityRegex = /^[a-zA-Z\s]+$/;

/**
 * Returns a list of available subjects based on the selected grade.
 * Using in the AgeGroupContainer and MembershipContainer to skip grade curriculum page if there're no subjects.
 */
export const getSubjectsByGrade = (
    subjectsByGrade: GradeInterface[],
    checkoutGrade: number
) => {
    const grades = subjectsByGrade?.filter(
        grade => grade.grade === checkoutGrade
    );

    const subjects = grades?.length !== 0 ? grades[0].subjects : [];
    return subjects;
};

export const convertDays = (days: number): string => {
    if (days >= 30) {
        const months = Math.floor(days / 30);
        const remainingDays = days % 30;
        let result = `${months} month${months > 1 ? "s" : ""}`;
        if (remainingDays > 0) {
            result += `, ${convertDays(remainingDays)}`;
        }
        return result;
    } else if (days >= 7) {
        const weeks = Math.floor(days / 7);
        const remainingDays = days % 7;
        let result = `${weeks} week${weeks > 1 ? "s" : ""}`;
        if (remainingDays > 0) {
            result += `, ${convertDays(remainingDays)}`;
        }
        return result;
    }
    return `${days} day${days > 1 ? "s" : ""}`;
};

export const filterFieldsByType = (fields: any[], type: string) => {
    return fields.filter(field => field.type === type);
};

export const getFieldsByStep = (data: any[], step: number): any[] | null => {
    // Find the step object that matches the given step
    const stepObject = data.find(item => item.step === step);

    if (!stepObject) {
        return null;
    }

    // Return the fields of the found step object
    return stepObject.fields;
};

export const getFieldByName = (
    fields: any[] | null,
    fieldName: string
): any | null => {
    if (!fields) return null;
    // Find the field object that matches the given field name
    const fieldObject = fields.find(field => field.name === fieldName);

    if (!fieldObject) {
        return null;
    }

    return fieldObject;
};

export const getTermStatus = (
    termLabel: string,
    availableTerms: string[],
    registeredTerms: string[],
    gradesRequiringCheck: any,
    startGrade: number | string | null,
    endGrade: number | string | null
): string => {
    // Validate and ensure startGrade and endGrade are defined
    const validStartGrade = startGrade ?? 0;
    const validEndGrade = endGrade ?? 0;

    // Registered terms show as "warning"
    if (registeredTerms?.includes(termLabel)) return "warning";

    // Available terms show as "success"
    if (availableTerms?.includes(termLabel)) return "success";

    // For gradesRequiringCheck, if terms cross into an unlicensed grade, show "warning"
    const termGrade = parseInt(termLabel.split(" ")[1]);
    if (
        gradesRequiringCheck?.start.includes(validStartGrade) &&
        gradesRequiringCheck?.end.includes(validEndGrade)
    ) {
        if (termGrade === validEndGrade) return "warning"; // No license for the end grade
    }

    // If the startGrade and endGrade do not match any ranges in gradesRequiringCheck show "error"
    if (
        !gradesRequiringCheck?.start.includes(validStartGrade) ||
        !gradesRequiringCheck?.end.includes(validEndGrade)
    ) {
        return registeredTerms.length === 0 ? "warning" : "error"; // The combination of startGrade and endGrade is not in the allowed range
    }

    // Terms not available or registered show as "error"
    return "error";
};

export const generateTerms = (
    startingGrade: string,
    startingTerm: string,
    grades: string[],
    terms: string[],
    availableTerms: string[] = [],
    registeredTerms: string[] = [],
    gradesRequiringCheck: any
) => {
    const termsPerGrade = 4;

    const gradeNumbers = grades?.map(grade => parseInt(grade.split(" ")[1]));
    const highestGrade = Math.max(...gradeNumbers);

    const nextGrade = highestGrade + 1;
    const nextGradeLabel = `Grade ${nextGrade}`;

    if (!grades?.includes(nextGradeLabel)) {
        grades.push(nextGradeLabel);
    }

    const startGradeIndex = grades.indexOf(startingGrade);
    const startTermIndex = terms.indexOf(startingTerm);

    if (startGradeIndex === -1 || startTermIndex === -1) {
        return [];
    }

    const result: { term: string; status: string }[] = [];
    let currentGradeIndex = startGradeIndex;
    let currentTermIndex = startTermIndex;

    for (let i = 0; i < termsPerGrade; i++) {
        if (currentGradeIndex >= grades.length) {
            break;
        }

        const term = `${grades[currentGradeIndex]} T${currentTermIndex + 1}`;
        const startGrade = parseInt(grades[startGradeIndex].split(" ")[1]);
        const endGrade = parseInt(grades[currentGradeIndex].split(" ")[1]);

        // Determine term status based on refined rules
        const termStatus = getTermStatus(
            term,
            availableTerms,
            registeredTerms,
            gradesRequiringCheck,
            startGrade,
            endGrade
        );

        result.push({
            term,
            status: termStatus
        });

        currentTermIndex++;
        if (currentTermIndex >= terms.length) {
            currentTermIndex = 0;
            if (currentGradeIndex < grades.length - 1) {
                currentGradeIndex++;
            }
        }
    }

    const hasLicenseGrades = result?.some(result =>
        availableTerms.includes(result.term)
    );
    const hasAlreadyRegistred = result?.every(result =>
        registeredTerms.includes(result.term)
    );

    if (!hasLicenseGrades && !hasAlreadyRegistred) {
        result?.forEach(term => (term.status = "error"));
    }

    return result;
};

export const extractGradeLevelFromLicensePlan = (planString: string) => {
    const gradePattern = /Grade-(\d+)/i;
    const match = planString.match(gradePattern);
    if (match) {
        return `Grade ${match[1]}`;
    }
};

export const proceedToStudentEnrolment = (token: string) => {
    router.get(
        route("enrol.student.base", {
            token
        })
    );
};
export const proceedToManageRegistrations = (userId: number) => {
    router.get(
        route("parents.manage-registrations", {
            studentId: userId
        })
    );
};

export const getQueryParam = (
    paramKey: string,
    defaultValue: string = "0"
): string => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(paramKey) ?? defaultValue;
};

export const proceedToDocumentRegistrations = (
    userId: number,
    regId: number
) => {
    router.get(
        route("parents.registration-documents", {
            studentId: userId,
            govRegId: regId
        })
    );
};

export const isServiceLicenseExpired = (endDateString: string): boolean => {
    const providedDate = new Date(endDateString);
    const currentDate = new Date();
    return currentDate > providedDate;
};

export const isTouchDevice = () => {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};

export const isLaptopOrDesktopUserAgent = (): boolean => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isSmallScreen = window.innerWidth < 1024;
    const isiPad =
        /ipad/.test(userAgent) ||
        (navigator.platform === "MacIntel" && isTouchDevice());
    if (isiPad) {
        return false;
    }
    const mobileKeywords = [
        "android",
        "iphone",
        "ipad",
        "ipod",
        "blackberry",
        "opera mini",
        "iemobile",
        "mobile"
    ];

    return (
        !mobileKeywords.some(keyword => userAgent.includes(keyword)) &&
        !(isSmallScreen || isiPad)
    );
};
